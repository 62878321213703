import BookStepsLayout from '../../app/common/BookStepsLayout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GenericScheduleEmbed } from '../../app/common/Embed/GenericScheduleEmbed';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PompaProgressBar from '../PompaProgressBar/PompaProgressBar';
import { getUrl } from '../../app/api/agent';
import axios from 'axios';

export const Time2Book2 = () => {
  const [searchParams] = useSearchParams();
  const name = searchParams.get('Name');
  const lastName = searchParams.get(decodeURIComponent('lastName'));
  const mobile = searchParams.get('mobile');
  const email = searchParams.get('email');

  const decodedName = decodeURIComponent(name);
  const decodedLastName = decodeURIComponent(lastName);
  const decodedMobile = decodeURIComponent(mobile);
  const decodedEmail = decodeURIComponent(email);

  const [toggle, setToggle] = useState(false);
  const [mainIframePage, setMainIframePage] = useState();
  const [mainIframeId, setMainIframeId] = useState();
  const [secondaryIframePage, setSecondaryIframePage] = useState();
  const [secondaryIframeId, setSecondaryIframeId] = useState();
  const [pixelDataSent, setPixelDataSent] = useState(false);
  const fetchAndSendPixelData = async () => {
    const hiddenFields = [
      '_fbc',
      '_fbp',
      'user_agent',
      'handl_ip',
      'gclid',
      'handl_url',
      'state',
      'handlID',
      'first_handlID',
      'handlID_ref',
      'fb_pixel_id',
    ];

    const pixelData = {};

    hiddenFields.forEach((field) => {
      const element = document.querySelector(`input[name="${field}"]`);
      if (element && element.value) {
        pixelData[field] = element.value;
      }
    });

    if (Object.keys(pixelData).length > 0 && !pixelDataSent) {
      setPixelDataSent(true);
      console.log('Pixel data ready:', pixelData);

      try {
        console.log('storing pixel data:', pixelData);
        await axios.post('https://api.pompaprogram.com/store-pixel-data', {
          email: decodedEmail,
          pixelData,
        });
        console.log('Pixel data sent successfully.');
      } catch (error) {
        console.error('Failed to send pixel data:', error);
      }
    }
  };

  const fetchPixelId = () => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }
      return null;
    };
    const pixelId = getCookie('fb_pixel_id');

    if (pixelId) {
      console.log(`Retrieved pixel ID from cookie: ${pixelId}`);
      const fbPixelInput = document.querySelector('input[name="fb_pixel_id"]');
      if (fbPixelInput) {
        fbPixelInput.value = pixelId;
        console.log(`fb_pixel_id input field updated with: ${pixelId}`);
      } else {
        console.warn('fb_pixel_id input field not found in the DOM.');
      }
    } else {
      console.log('fb_pixel_id cookie not found');
    }
    return pixelId;
  };

  const loadFacebookScript = async () => {
    const pixelId = await fetchPixelId();
    if (pixelId) {
      console.log('Loading new Facebook script pixelId:', pixelId);
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://connect.facebook.net/en_US/fbevents.js';
      document.body.appendChild(script);

      window.fbq = function () {
        window.fbq.callMethod
          ? window.fbq.callMethod(...arguments)
          : window.fbq.queue.push(arguments);
      };
      if (!window._fbq) window._fbq = window.fbq;
      window.fbq.loaded = true;
      window.fbq.version = '2.0';
      window.fbq.queue = [];
      window.fbq('init', pixelId);
      window.fbq('track', 'PageView');

      // TrackSimple snippet
      const trackSimpleConfig = document.createElement('script');
      trackSimpleConfig.innerHTML = `
    fb_pixel_id = '${pixelId}';
    handl_custom_params = ['utm_partner'];
    var handl_js = document.createElement("script");
    handl_js.setAttribute("src", "https://track.utmsimple.com/utm.js?license=7d4856e0322e11eca0d731cb18926450");
    document.head.appendChild(handl_js);
  `;
      document.head.appendChild(trackSimpleConfig);
    } else {
      console.log('No pixelId found, loading old Facebook script');
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://connect.facebook.net/en_US/fbevents.js';
      document.body.appendChild(script);

      window.fbq = function () {
        window.fbq.callMethod
          ? window.fbq.callMethod(...arguments)
          : window.fbq.queue.push(arguments);
      };
      if (!window._fbq) window._fbq = window.fbq;
      window.fbq.loaded = true;
      window.fbq.version = '2.0';
      window.fbq.queue = [];
      window.fbq('init', '8598317380190111');
      window.fbq('track', 'PageView');
      console.log('Old Facebook script loaded');

      // TrackSimple snippet
      const trackSimpleConfig = document.createElement('script');
      trackSimpleConfig.innerHTML = `
    fb_pixel_id = '8598317380190111';
    handl_custom_params = ['utm_partner'];
    var handl_js = document.createElement("script");
    handl_js.setAttribute("src", "https://track.utmsimple.com/utm.js?license=7d4856e0322e11eca0d731cb18926450");
    document.head.appendChild(handl_js);
  `;
    }
  };

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * 100);

    fetch(getUrl() + `/split-percentage`)
      .then((response) => response.json())
      .then((data) => {
        const percentage = data.percentage;
        const useMainCalendar = randomNumber <= percentage;
        console.log('your number is: ', randomNumber);
        console.log('useMainCalendar: ', useMainCalendar);
        setMainIframePage(
          useMainCalendar ? 'scheduleoncedouble' : 'additionaltimes2'
        );
        setMainIframeId(
          useMainCalendar
            ? 'SOIDIV_scheduleoncedouble'
            : 'SOIDIV_additionaltimes2'
        );
        setSecondaryIframePage(
          useMainCalendar ? 'additionaltimes2' : 'scheduleoncedouble'
        );
        setSecondaryIframeId(
          useMainCalendar
            ? 'SOIDIV_additionaltimes2'
            : 'SOIDIV_scheduleoncedouble'
        );
      })
      .catch((error) => console.error('Failed to fetch main calendar', error));
    loadFacebookScript();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!pixelDataSent) {
        fetchAndSendPixelData();
      } else {
        clearInterval(interval);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [pixelDataSent]);
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <BookStepsLayout>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: '#efe9e5',
          height: '220px',
          textAlign: 'center',
          '@media (max-width: 700px)': {
            height: 'auto',
            paddingBottom: '40px',
          },
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '38px',
            textAlign: 'center',
            fontWeight: '700',
            marginBottom: '20px',
            marginTop: '50px',
            '@media (max-width: 700px)': {
              fontSize: '28px',
              marginTop: '20px',
            },
          }}
        >
          WAIT! YOUR ORDER IS NOT COMPLETE.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            marginTop: '0 !important',
            fontSize: '30px',
            fontStyle: 'italic',
            color: 'red',
            textAlign: 'center',
            fontWeight: 'bold',
            maxWidth: '1150px',
            width: '100%',
            margin: 'auto',
            '@media (max-width: 700px)': {
              width: '90%',
            },
          }}
        >
          <PompaProgressBar width="85%" message="Almost Complete..." />
        </Typography>
      </Box>
      <div
        style={{
          margin: 'auto',
          alignItems: 'center',
          display: 'flex',
          maxWidth: '900px',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingBottom: '40px',
          paddingTop: '20px',
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '32px',
            textAlign: 'center',
            fontWeight: 'bold',
            margin: 'auto',
            mb: 2,
            mt: 2,
            '@media (max-width: 700px)': {
              fontSize: '22px',
            },
          }}
        >
          Book your appointment for an available time below.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '26px',
            textAlign: 'center',
            fontWeight: 'bold',
            margin: 'auto',
            mb: 4,
            mt: 0,
            '@media (max-width: 700px)': {
              fontSize: '22px',
              mb: 2,
            },
          }}
        >
          If there are no times that work for you, please book an appointment
          and you will be able to reschedule when we reach out to you via text.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '16px',
            textAlign: 'center',
            margin: 'auto',
            mb: 4,
            mt: 0,
            '@media (max-width: 700px)': {
              fontSize: '14px',
            },
          }}
        >
          Please use the same email that you purchased with and verify your time
          zone before booking. If you don't see a scheduler below, please{' '}
          <Box
            component="span"
            sx={{
              color: 'rgb(90, 90, 190)',
              cursor: 'pointer',
            }}
            onClick={refreshPage}
          >
            click here
          </Box>{' '}
          to refresh the page now.
        </Typography>

        <div
          style={{
            display: !toggle ? 'block' : 'none',
            width: '80%',
            height: '548px',
            maxWidth: '900px',
          }}
        >
          {mainIframeId && (
            <GenericScheduleEmbed
              iframeId={mainIframeId}
              iframePage={mainIframePage}
              email={decodedEmail}
              name={decodedName}
              lastName={decodedLastName}
              phone={decodedMobile}
            />
          )}
        </div>
        <div
          style={{
            width: '80%',
            height: '585px',
            maxWidth: '900px',
            display: toggle ? 'block' : 'none',
          }}
        >
          <div
            style={{
              border: '5px solid #9ec2e5',
              borderRadius: '4px',
            }}
          >
            <Typography
              component="h5"
              variant="h5"
              style={{ textAlign: 'center' }}
            >
              Additional Times
            </Typography>
            {secondaryIframeId && (
              <GenericScheduleEmbed
                iframeId={secondaryIframeId}
                iframePage={secondaryIframePage}
                email={decodedEmail}
                name={decodedName}
                lastName={decodedLastName}
                phone={decodedMobile}
              />
            )}
          </div>
        </div>

        {/*<Typography*/}
        {/*  component="h4"*/}
        {/*  variant="h4"*/}
        {/*  sx={{*/}
        {/*    fontSize: '16px',*/}
        {/*    textAlign: 'center',*/}
        {/*    margin: 'auto',*/}
        {/*    mb: 30,*/}
        {/*    mt: 4,*/}
        {/*    '@media (max-width: 700px)': {*/}
        {/*      fontSize: '14px',*/}
        {/*    },*/}
        {/*  }}*/}
        {/*>*/}
        {/*  For additional times and availability,{' '}*/}
        {/*  <Box*/}
        {/*    component="span"*/}
        {/*    sx={{*/}
        {/*      color: 'rgb(90, 90, 190)',*/}
        {/*      cursor: 'pointer',*/}
        {/*      textDecoration: 'underline',*/}
        {/*    }}*/}
        {/*    onClick={() => setToggle(!toggle)}*/}
        {/*  >*/}
        {/*    click here*/}
        {/*  </Box>*/}
        {/*</Typography>*/}
        {/* Facebook Pixel Hidden Fields */}
        <input type="hidden" name="_fbc" />
        <input type="hidden" name="_fbp" />
        <input type="hidden" name="user_agent" />
        <input type="hidden" name="handl_ip" />
        <input type="hidden" name="gclid" />
        <input type="hidden" name="handl_url" />
        <input type="hidden" name="state" />
        <input type="hidden" name="handlID" />
        <input type="hidden" name="first_handlID" />
        <input type="hidden" name="handlID_ref" />
        <input type="hidden" name="fb_pixel_id" />
        <Box mb={2} />
      </div>
    </BookStepsLayout>
  );
};
